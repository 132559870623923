// Variables
$clr-primary: #3d6db9;
$clr-accent: #15b76c;
$clr-warn: #eb5757;
$clr-yellow: #FFC200;
$clr-white: #ffffff;
$clr-green-100: rgba(26, 229, 134, 1);
$clr-blue-100: #d3e4fe;
$clr-blue-200: #7895c7;
$clr-blue-700: #006BFF;
$clr-blue-800: #062C77;
$clr-blue-900: #04327A;
$clr-blue-1000: #042150;

// Transcription microphone
$clr-blue-300: #A0C3FF;

// Text
$ff-color-000: #6f6f6f;
$ff-color-100: #616161;
$ff-color-200: #424242;
$ff-color-300: #242424;
$ff-color-white: #fff;

// Gray
$clr-gray-000: #cacaca;
$clr-gray-100: #a8a8a8;
$clr-gray-200: rgba(97, 97, 97, 1);
$clr-gray-300: #ebebeb;
$clr-gray-400: #2f2f2f;
$clr-gray-500: #e5e5e5;
$clr-gray-600: rgba(97, 97, 97, 0.09);
$clr-gray-700: #bfbfbf;
$clr-gray-800: #e4e4e4;
$clr-gray-900: #d9d9d9;
$clr-gray-1000: #f2f2f2;
$clr-gray-1100: #4C4C4C;
$clr-gray-1200: #767676;

// Black
$clr-black-100: #404040;
$clr-black-200: #292929;

// External meeting
$clr-external-meeting: $clr-gray-1000;
$clr-external-meeting-content: $clr-black-200;

// Internal meeting
$clr-internal-meeting: $clr-blue-100;
$clr-internal-meeting-content: $clr-black-200;

// Current day
$clr-current-day: $clr-primary;
$clr-current-day-content: white;
$clr-current-day-add-button: $clr-blue-100;
$clr-current-day-add-button-content: $clr-black-200;

$clr-answered-call: #00CAC4;
$clr-not-answered-call: #E3371E;
$clr-busy-call: #FFC200;

$clr-total-calls: $clr-primary;
$clr-outbound-calls: #00307C;
$clr-inbound-calls: #006AFF;
$clr-internal-calls: #CDCDCD;

// LCS Admin panel
$clr-admin-panel-button: $clr-blue-800;
$clr-admin-panel-background: #FAFAFA;
$clr-admin-panel-card-background: white;
$clr-admin-panel-button-disabled: $clr-gray-100;
$clr-admin-panel-active-link: #18BFFF;


