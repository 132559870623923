@import "variables.scss";
@import "@ctrl/ngx-emoji-mart/picker";
@import "~quill/dist/quill.snow.css"; // or 'quill.snow.css' or 'quill.core.css'

:root {
  ---navbar-width: 85px;
  ---category-navbar-width: 300px;
  ---header-height: 48px;
  ---table-opacity-hover: 0.7;
  ---room-header-height: 110px;
  ---space-between-panel-and-composer: 10px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  --cor-bg-principal: #18bfff;
  --fx-gap: 16px;
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* -- START TYPOGRAPHY -- */
.heading-1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 62px;
}
.heading-2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 53px;
}
.heading-3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
}
.heading-4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 35px;
}
.heading-5 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
}
.heading-6 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
}
.extra-large-text-bold {
  font-family: Roboto !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 42px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}
.extra-large-text-regular {
  font-family: Roboto !important;
  font-size: 30px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 42px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
}
.large-text-bold {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px !important;
}
.large-text-regular {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 28px !important;
}
.medium-text-bold {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 25.2px !important;
}
.medium-text-regular {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 25.2px !important;
}
.normal-text-bold {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.normal-text-regular {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.small-text-regular {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
}
.small-text-bold {
  font-family: Roboto !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
}
/* -- END TYPOGRAPHY -- */

.full-width {
  width: 100%;
}
.text-center {
  text-align: center;
}
.m-h-auto {
  margin: auto 0 !important;
}
.m-t-xs {
  margin-top: 5px !important;
}
.m-t-sm {
  margin-top: 10px !important;
}
.m-r-xs {
  margin-right: 5px !important;
}
.m-l-xs {
  margin-left: 5px !important;
}
.text-left {
  text-align: left !important;
}
.dark-backdrop {
  background-color: rgba(26, 26, 26, 0.95);
}
.error.mat-snack-bar-container {
  color: $clr-white !important;
  background-color: $clr-warn !important;
}
.success.mat-snack-bar-container {
  color: $clr-white !important;
  background-color: $clr-accent !important;
}
.warning.mat-snack-bar-container {
  color: $clr-white !important;
  background-color: $clr-yellow !important;
}
.mat-menu-item {
  padding: 0 !important;
}
.mat-bottom-sheet-container {
  transform: translateY(0%);
  min-width: 100vh;
  min-height: 100vh;
  z-index: 9999999999;
  overflow: hidden;
  padding: 0 !important;
}

/* -- START USER BADGES -- */
.light-background .extension-status,
.light-background .presence-status {
  border: 1px solid $clr-white;
}
.dark-background .extension-status,
.dark-background .presence-status {
  border: 1px solid $clr-black-200;
}
.has-no-register {
  border: 2px solid $clr-warn;
}
.avatar-header .extension-status,
.avatar-header .presence-status {
  border: 1px solid $clr-blue-900;
}
.is-small .extension-status,
.is-small .presence-status {
  width: 15px !important;
  height: 15px !important;
}
/* -- END USER BADGES -- */

/* -- START DARK THEME -- */
.dark-theme.mat-app-background input {
  color: $ff-color-100;
}
.dark-theme.mat-app-background input::placeholder {
  color: $clr-gray-200;
}
.dark-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
/* -- END DARK THEME -- */

.user-dialog .mat-dialog-container {
  width: 650px;
}

.custom-dialog-container {
  width: 650px;
  border-radius: 20px;
}

/* -- START QUILL -- */

.ql-mention-list-container {
  background-color: $clr-gray-1000;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.8) !important;
  max-height: 250px !important;
  overflow-y: auto;
  border-radius: 4px;
}

.ql-mention-list {
  list-style-type: none;
  padding: 0px;
  z-index: 100;
  margin: 0px;
}

.dark {
  .ql-mention-list-container {
    background-color: $clr-gray-1100;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 1) !important;
  }
}

.ql-mention-list .ql-mention-list-item {
  cursor: pointer;
  padding: 3px 5px;
  display: flex;
  align-items: center;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.ql-mention-list .selected {
  background-color: $clr-primary;
  opacity: 0.9;
  color: white;
}

span.mention {
  background-color: $clr-primary;
  padding: 2px;
  border-radius: 2px;
  color: white;
}
/* -- END QUILL -- */

/* -- START Unread count -- */
.unread-count {
  font-weight: bold;
  background-color: #6a6666;
  border-radius: 100%;
  font-size: smaller;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  height: 15px;
  width: 15px;
  color: white;
  margin-left: 5px;
}
/* -- END Unread count -- */

// --- START MAT CHIPS --- //
.chip-is-admin {
  background-color: $clr-primary !important;
  color: white !important;
}
// --- END MAT CHIPS --- //

.row {
  display: flex !important;
}

.fx-gap {
  gap: var(--fx-gap);
}

.column {
  display: flex !important;
  flex-direction: column !important;
}

/* -- START DIALOGS -- */
.dialog-save-btn {
  background-color: $clr-blue-900 !important;
  color: white !important;
  text-transform: uppercase !important;
}
mat-dialog-actions {
  button {
    text-transform: uppercase !important;
    padding: 0px 30px !important;
  }
}
.filter-logs-dialog {
  width: 550px;
}
.day-detail-dialog {
  width: 300px;
}
.select-chat-dialog {
  max-height: 550px;
  width: 35%;
  min-width: 350px;
}
.room-search-dialog {
  height: auto;
  width: 65%;
  min-width: 350px;
}
.conference-link-dialog {
  width: 500px;
}
.dialog-logs {
  width: 700px;
}

@media only screen and (max-width: 960px) {
  .conference-link-dialog {
    width: 400px;
  }

  .room-search-dialog {
    width: 100%;
  }
}
.forward-msg-dialog {
  max-height: 550px;
  width: 50%;
  min-width: 350px;
}
.search-event-dialog {
  min-height: 410px;
  width: 60%;
  min-width: 400px;
}
.desktop-settings-dialog {
  width: 40%;
  min-width: 350px;
}
.group-settings-dialog {
  width: 30%;
  min-width: 350px;
}
.room-full-info-dialog {
  width: 700px;
}
.meet-props-dialog {
  max-height: 90vh !important;
}
.confirm-deletion-dialog {
  max-width: 600px !important;
}
.simple-dialog {
  width: 450px;
}
.similar-contacts-dialog {
  width: 40%;
  min-width: 450px;
}
.confirm-leave-and-delete-msg {
  width: 450px;
}
.confirm-deletion-msg {
  width: 400px;
  height: 125px;
  h1 {
    font-size: medium !important;
    margin-bottom: 0px !important;
  }
}
.full-screen-dialog {
  height: auto;
  width: auto;
  max-width: 90vw;
  max-height: 90vh;
}
.full-screen-backdrop {
  background: rgba(0, 0, 0, 0.7);
}
/* -- END DIALOGS -- */

.display-none {
  display: none !important;
}

/* -- START Picker search -- */
.picker-search {
  width: 100%;
  margin-bottom: 10px;
}

.picker-search.dark {
  input {
    color: $clr-gray-100;
  }
  input::placeholder {
    color: $clr-gray-100;
  }
}
/* -- END Picker search -- */

/* -- START SCROLLBAR -- */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 100vw;
  margin-block: 0.1em;
}
::-webkit-scrollbar-thumb {
  max-width: 6px;
  border-radius: 100vw;
  background: #e5e5e5;
}
::-webkit-scrollbar-thumb:hover {
  background: hsl(0, 0%, 80%);
}
@supports (scrollbar-color: red blue) {
  * {
    scrollbar-color: #e5e5e5 #f5f5f5;
    scrollbar-width: thin;
  }
}
body.dark-theme {
  ::-webkit-scrollbar-track {
    background: hsl(0, 0%, 20%);
  }
  ::-webkit-scrollbar-thumb {
    background: hsl(0, 0%, 30%);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: hsl(0, 0%, 30%);
  }

  @supports (scrollbar-color: red blue) {
    * {
      scrollbar-color: hsl(0, 0%, 30%) hsl(0, 0%, 20%);
      scrollbar-width: thin;
    }
  }
}
/* -- END SCROLLBAR -- */

/* -- START Text transformation -- */
.text-in-lowercase {
  text-transform: lowercase;
}

.show-only-first-letter {
  font-size: 0px !important;
}

.show-only-first-letter:first-letter {
  font-size: 1rem !important;
}
/* -- END Text transformation -- */

/* -- START loader effect -- */
.loader,
.loader:before,
.loader:after {
  background: #3d6db9;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #3d6db9;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
/* -- END loader effect -- */

/* -- START BLINK effect -- */
.blink {
  animation: blink 1s linear infinite;
  -webkit-animation: blink 1s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
/* -- END BLINK effect -- */

/* -- START Small mat icon -- */
.small-size .mat-icon {
  width: 19px !important;
  height: 19px !important;
}

/* -- START Highlight -- */
.search-highlight {
  color: black;
  background-color: #ffff00;
}
.search-highlight.search-active {
  background-color: #ff0000;
}
/* -- END Highlight -- */

/* -- START CENTER CONTAINERS -- */
.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* -- END CENTER CONTAINERS -- */

/* -- START TRANSFORMS ICONS -- */
.flip-icon {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
/* -- END TRANSFORMS ICONS -- */

/* -- START - User Status -- */
.status-container {
  position: absolute;
  bottom: -4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.extension-status,
.presence-status {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $clr-gray-100;
}

.ExtensionUserAvailable.extension-status,
.ExtensionAvailable.extension-status,
.Available.presence-status {
  background-image: url("assets/images/icon-available.svg") !important;
}

.ExtensionUserAway.extension-status,
.ExtensionAway.extension-status,
.Away.presence-status {
  background-image: url("assets/images/icon-away.svg") !important;
}

.ExtensionUserBeRightBack.extension-status,
.ExtensionBeRightBack.extension-status,
.BeRightBack.presence-status {
  background-image: url("assets/images/icon-away.svg") !important;
}

.ExtensionUserInMeeting.extension-status,
.InMeeting.presence-status {
  background-image: url("assets/images/icon-in-meeting.svg") !important;
}

.ExtensionUserInCall.extension-status,
.ExtensionInCall.extension-status,
.InCall.presence-status {
  background-image: url("assets/images/icon-phone-in-call.svg") !important;
}

.ExtensionUserBusy.extension-status,
.ExtensionBusy.extension-status,
.Busy.presence-status {
  background-image: url("assets/images/icon-busy.svg") !important;
}

.ExtensionUserDoNotDisturb.extension-status,
.ExtensionDoNotDisturb.extension-status,
.DoNotDisturb.presence-status,
.ExtensionUserInPresentation.extension-status,
.ExtensionInPresentation.extension-status,
.InPresentation.presence-status {
  background-image: url("assets/images/in-presentation.svg") !important;
}

.ExtensionUserOffline.extension-status,
.ExtensionFullOffline.extension-status,
.Offline.presence-status {
  background-image: url("assets/images/icon-offline.svg") !important;
}

.ExtensionOffline.extension-status {
  background-image: url("assets/images/icon-phone-off.svg") !important;
}

.ExtensionUserOnlyPush.extension-status,
.ExtensionOnlyPush.extension-status,
.OnlyPush.presence-status {
  background-image: url("assets/images/icon-smartphone.svg") !important;
}

/* -- END - User Status -- */

/* -- START TIME SELECTOR -- */
.time-selector {
  margin-top: 30px !important;
  right: 0px;
}
/* -- END TIME SELECTOR -- */

/* -- START COLLABORATION BG -- */
.bg-collaboration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $clr-gray-100;
  font-weight: lighter;

  .collaboration-img {
    width: 450px;
    height: auto;
    margin-bottom: 30px;
  }

  h1 {
    margin-bottom: 8px !important;
  }

  p {
    text-align: center;
    width: 50%;
    font-size: 16px;
  }
}
/* -- END COLLABORATION BG -- */

.chart-card {
  height: auto;
  padding: 30px;
}

.chart-card-content {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;

  div {
    width: 95% !important;
  }
}

.clickable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.grid-list {
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.grid-item {
  padding: 15px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  border-right: 0.5px solid rgba(0, 0, 0, 0.1);
}
